// Generated by Framer (7aa0232)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Carousel from "https://framerusercontent.com/modules/UIrMjSS6ZX89L0CsT8k6/w90zR0qzeh1mgaDSvD54/Carousel.js";
const CarouselFonts = getFonts(Carousel);

const cycleOrder = ["KKACJK8LL"];

const variantClassNames = {KKACJK8LL: "framer-v-1e8dlyw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KKACJK8LL", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7eHWo", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1e8dlyw", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KKACJK8LL"} ref={ref} style={{backgroundColor: "rgb(252, 252, 255)", ...style}}><motion.div className={"framer-dh6nq3"} layoutDependency={layoutDependency} layoutId={"m39g2ekPR"}><motion.div className={"framer-n8vue0-container"} layoutDependency={layoutDependency} layoutId={"VLFyxuDBM-container"}><Carousel align={"center"} ariaLabel={""} arrowObject={{arrowFill: "rgba(0, 0, 0, 0.2)", arrowPadding: 20, arrowRadius: 40, arrowSize: 40, showMouseControls: true}} axis borderRadius={0} fadeObject={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeTransition: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}, fadeWidth: 25}} gap={10} height={"100%"} id={"VLFyxuDBM"} layoutId={"VLFyxuDBM"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} progressObject={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 4, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: false, showScrollbar: false}} sizingObject={{heightInset: 0, heightRows: 2, heightType: "auto", widthColumns: 2, widthInset: 0, widthType: "auto"}} slots={[]} snapObject={{fluid: false, snap: true, snapEdge: "center"}} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7eHWo [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7eHWo .framer-1qxs5rp { display: block; }", ".framer-7eHWo .framer-1e8dlyw { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 682px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1400px; }", ".framer-7eHWo .framer-dh6nq3 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 545px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 100%; }", ".framer-7eHWo .framer-n8vue0-container { flex: 1 0 0px; height: 200px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7eHWo .framer-1e8dlyw, .framer-7eHWo .framer-dh6nq3 { gap: 0px; } .framer-7eHWo .framer-1e8dlyw > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-7eHWo .framer-1e8dlyw > :first-child { margin-top: 0px; } .framer-7eHWo .framer-1e8dlyw > :last-child { margin-bottom: 0px; } .framer-7eHWo .framer-dh6nq3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-7eHWo .framer-dh6nq3 > :first-child { margin-left: 0px; } .framer-7eHWo .framer-dh6nq3 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 682.4644549763034
 * @framerIntrinsicWidth 1400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerwHy3BHalm: React.ComponentType<Props> = withCSS(Component, css, "framer-7eHWo") as typeof Component;
export default FramerwHy3BHalm;

FramerwHy3BHalm.displayName = "slider-mobile";

FramerwHy3BHalm.defaultProps = {height: 682.4644549763034, width: 1400};

addFonts(FramerwHy3BHalm, [...CarouselFonts])